import React from 'react';

import { useHistory } from 'react-router';

import styles from './Main.module.scss';

const Main: React.FC = () => {
  const history = useHistory();
  return (
    <div className={styles.mainWrapper}>
      <h1 className={styles.title}>연애 신청서</h1>
      <h3 className={styles.name}>우현민</h3>
      <button
        className={styles.nextBtn}
        onClick={() => {
          const confirmed = window.confirm(
            '평화로운 연애를 하던 어느 날, 하늘의 신이 나타나 말했습니다. ' +
              '계속 연애를 하기 위해서는 나에게 허가를 받아야 하느니라. ' +
              '당신의 남자친구는, 연애를 계속 하기 위해 연애 신청서를 작성하는 모험을 떠났습니다! ' +
              '남자친구의 여정을 함께 살펴보시겠습니까?'
          );
          if (confirmed) history.push('/suhyunmin2year/contents');
          else window.alert('왜요 ㅡㅡ 봐줘요 ㅡㅡ');
        }}
      >
        다음으로
      </button>
    </div>
  );
};

export default Main;
