export const Letter = [
  [
    `내 이쁜 애기에게`,
    `안녕 수현이! 저는 수현이 남자친구 우현민입니다!`,
    `2주년을 맞아 새로운 시도를 해 봤는데 어때요? 마음에 들었으면 좋겠다 헤헤`,
    `근데 내가 봐도 저 앞에까지 딱히 감동 포인트가 없어서... 다음엔 좀더 열심히 준비해야겠다고 절실히 느꼈어요ㅠㅠ`,
    ``,
    `원래 완전 서프라이즈로 막 안에서 불꽃도 날라다니고 내사진도 날라다니고 그렇게 하려고 했는데!`,
    `예상보다 내가 더 때지였어 엉엉`,
    ``,
    `울애기는 내 뒤에서 잘 자고 있으니까 본격적으로 편지를 시작해 보겠즘다! 참고로 이번 컨셉은 큐티입니다!`,
    ``,
  ],
  [
    `우선, 우리가 만난 지 벌써 2주년이 되었어요! 와아아아아ㅏ앙 축하해!!!!`,
    ``,
    `이번은 내가 회사 들어가고 맞는 첫 기념일이에요!`,
    `환경이 엄청엄청 많이 바뀌고, 항상 붙어있던 우리가 꽤나 떨어져 있게 되었어요ㅠㅠ, 한 달이 지났는데, 어땠나요?`,
    `그래도 처음에는 엄청 허전해했는데 이젠 우리 둘 다 잘 적응한 거 같아서 다행이에요`,
    ``,
    `일하느라 정신도 없고 시간도 없고 해서 사실 나름 많이 걱정했는데, 그래도 나름 우리만의 훌륭한 패턴? 을 찾은 거 보니까, 역시 우리다 싶어요!`,
    `정말 긴 시간 많이 붙어있으면서도 아직도 이렇게 애틋한 거 보면 정말 우리는 맞는 무언가가 있나 봐요><`,
  ],
  [
    `애기 요즘 나 엄청 챙겨주는거같아요! 짱행복! 요즘 너무 행복해요! 근데 내가 행복한만큼 나도 더 챙겨줘야 하는데 어 으 어 헤헤.. 헤헤!`,
    `너무 수려한 외모와 타고난 귀여움으로만 다 해치우려는 느낌이랄까? 재수없어`,
    `아무튼 요지는 그게 아니라 내가 요즘 너무나도 행복하다는 거지. 세상 어느 여친이 남친 시험 늦을가봐 아침잠을 이기고 택시타고 깨우러 와 주니?`,
    `아플 때마다 챙겨주고, 배아프다고 할 때마가 쓰다듬해주고, 만져달라고 할 때마다 만져주고,`,
    `맛있는 것도 많이 먹여주고, 좋은 데도 많이 데려가 주고, 재밌는 것도 많이 보여주고!`,
  ],
  [
    `아마 요즘 우리의 주된 트러블 이슈는 하루를 공유하지 않는다는 점? 인 것 같아! 그런데 또 나는 딱히 퇴근하고 나서 회사 일을 생각하게 되지 않더라구..ㅠㅠ`,
    `그래서 앞으로는 매일 작게나마 일기를 쓰도록 하겠습니다! 어떤가요?`,
    `간단하게 무슨 일이 있었는지, 무슨 변화가 생겼는지 정도를 써보려고 해요!`,
    `애기도 같이 쓴다면 이게 더 지속될 수도 있지 않을까 싶기도 하구.. 근데 아무튼, 지금 애기가 더 서운해하고 속상해하니까 내가 더 노력하려구 헤헤`,
  ],
  [
    `고마워요 애기야, 돌이켜보면 애기가 나한테 진짜 많이 맞춰주고 도와주고 있구나 싶어요`,
    `지금 그렇게 잘 나가고 있는진 잘 모르겠지만 애기 덕분에 여기까지 올 수 있었고, 나도 애기한테 많은 힘이 되었길 바라요`,
    `앞으로도 우리 항상 서로 도와주고 위로해주고 보듬어주고 사랑해주면서, 같이 행복하자!`,
    `어떻게 끝을 내야 할지 잘 모르겠으니까 여기 읽을 때쯤 그냥 뽀뽀해버려야지 뿅`,
  ],
];
