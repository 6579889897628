import React, { createContext, useState } from 'react';

import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';

import Letter1 from './Main/Contents/ContentLetters/Letter1/Letter1';
import Letter2 from './Main/Contents/ContentLetters/Letter2/Letter2';
import Letter3 from './Main/Contents/ContentLetters/Letter3/Letter3';
import Letter4 from './Main/Contents/ContentLetters/Letter4/Letter4';
import Contents from './Main/Contents/Contents';
import Main from './Main/Main';

import styles from './SHM2YearPage.module.scss';

interface IOpenContext {
  opened: string[];
  setOpened: React.Dispatch<React.SetStateAction<string[]>>;
}

export const OpenContext = createContext<IOpenContext>({ opened: [], setOpened: () => null });

const SHM2YearPage: React.FC = () => {
  const [opened, setOpened] = useState<string[]>([]);
  const history = useHistory();
  const location = useLocation();

  return (
    <OpenContext.Provider value={{ opened, setOpened }}>
      <header className={styles.goBackBtnWrapper}>
        {location.pathname !== '/suhyunmin2year/' && (
          <button className={styles.goBackBtn} onClick={() => history.goBack()}>
            뒤로가기
          </button>
        )}
      </header>
      <Switch>
        <Route path="/suhyunmin2year/contents/1" component={Letter1} />
        <Route path="/suhyunmin2year/contents/2" component={Letter2} />
        <Route path="/suhyunmin2year/contents/3" component={Letter3} />
        <Route path="/suhyunmin2year/contents/4" component={Letter4} />
        <Route path="/suhyunmin2year/contents" component={Contents} />
        <Route path="/suhyunmin2year" component={Main} />
        <Redirect to="/" />
      </Switch>
    </OpenContext.Provider>
  );
};

export default SHM2YearPage;
