import React, { useContext, useEffect } from 'react';

import { OpenContext } from '../../../../SHM2YearPage';

import styles from './Letter1.module.scss';

interface Props {}

const Letter1: React.FC<Props> = () => {
  const date = new Date();
  const dayAfterBirth = Math.ceil((date.getTime() - new Date('2001.03.25').getTime()) / 1000 / 3600 / 24);

  const { setOpened } = useContext(OpenContext);

  useEffect(() => {
    setOpened((opened) => opened.concat('1'));
  }, [setOpened]);

  return (
    <div className={styles.letterWrapper}>
      <div className={styles.letter}>
        <h1 className={styles.title}>이 력 서</h1>
        <section className={styles.content}>
          {/* 인적사항 파트 */}
          <hr />
          <hr />
          <h3>인적사항</h3>
          <span />
          <hr />
          <hr />
          <span>이름</span>
          <span>우현민</span>
          <span>성별</span>
          <span>아기</span>
          <span>생년월일</span>
          <span>2001.03.25 (만20세)</span>
          <span>연락처</span>
          <span>
            010-6558-2335 <br />
            010-4981-9662 (보호자) <br />
            woohm404@gmail.com <br />
          </span>
          <span>주소</span>
          <span>항상 울 애기 맘속에</span>
          <span />
          <span />

          {/* 정보 파트 */}
          <hr />
          <hr />
          <h3>정보</h3>
          <span />
          <hr />
          <hr />
          <span>수치</span>
          <span>
            숨쉰 날 수 : {dayAfterBirth}일 <br />
            먹은 밥 수 :{Math.ceil(dayAfterBirth * 2.5) + Math.ceil(Math.random() * 1000)}끼
            <br />
            안경 알 수: 2<br />
            아는 노래 수 :{Math.ceil(dayAfterBirth * 1.14 + Math.random() * 120)}
            <br />
            머리털 수 : 114728가닥 <br />
            <b style={{ textDecoration: 'line-through', marginLeft: 20 }}>실시간 반영 안되니까 뽑지 마시오</b>
            <br />
            자위 횟수: 3185회
            <br />
          </span>
          <span />
          <span />
          <span>신체조건</span>
          <span>
            키: 169cm <br />
            몸무게: 60kg (예상) <br />
            악력: 오른손 54kg, 왼손 31kg
            <br />
            치악력: 35kg
            <br />
            BMI: 마지막으로 쟀을 때 18이었는데 지금은 아마 21 <br />
            길이: 6 ~ 15cm <br />
            굵기: 휴지심과 동일 <br />
            주량: 0.14 ~ 2병 <br />
            한 뼘: 22cm <br />
            시력: 0.6 / 0.2
            <br />
            청력: 겁나 잘들음 <br />
            발음: 조금 구린듯 <br />
          </span>
          <span />
          <span />
          <span>성격</span>
          <span>
            총평: 다소 또라이 기질이 있음 <br />
            --성실성: 40점
            <br />
            --창의성: 90점
            <br />
            --일관성: 100점
            <br />
            --감수성: 10점
            <br />
            --자금성: 중국
            <br />
            --큼성: 우와 크다
            <br />
            --논리성: 90점 <br />
            --신뢰도: 100점 <br />
            --남성: male <br />
          </span>

          {/* 장점 파트 */}
          <hr />
          <hr />
          <h3>강점</h3>
          <span />
          <hr />
          <hr />

          <span />
          <span>
            <p>밥을 아주 맛있게 잘 먹음</p>
            <p>노래를 잘 부르려고 노력을 함!</p>
            <p>귀여운 이모티콘을 많이 가지고 있음</p>
            <p>열심히 살려고 노력을 함!</p>
            <p>우현민임</p>
            <p>몸에 열이 많음!</p>
            <p>운동을 하려고 노력이라도 함!</p>
            <p>직장인임!</p>
            <p>나름! 사진 잘 찍고 장비도 있음</p>
          </span>

          {/* 연애 이력 파트 */}
          {/* TODO 사진 추가 */}
          <hr />
          <hr />
          <h3>연애 이력</h3>
          <span />
          <hr />
          <hr />
          {[
            190224,
            '첫 연락 시작',
            190310,
            '첫 밥',
            190325,
            '첫 스킨십',
            190410,
            '첫키스',
            190423,
            '에드 시런 콘서트',
            190428,
            '1일',
            190503,
            '여자친구 보러 안암에 감',
            190606,
            '여자친구가 물을 뿜게 함',
            190707,
            '박효신 콘서트',
            190810,
            '장모님 만나뵘',
            190908,
            '캐리비안 베이',
            190924,
            '사진 촬영',
            191005,
            '불꽃축제',
            191019,
            '첫 섹스',
            191113,
            '찜질방',
            191227,
            '출국',
            200107,
            '여자친구 생일',
            200113,
            'ㅠㅠ1',
            200213,
            '광주 데이트',
            200307,
            '가출',
            200511,
            '장염',
            200606,
            '방울토마토 떨어트림',
            200618,
            '남산',
            200624,
            '호캉스: 호텔 로프트',
            200710,
            '월미도',
            200826,
            '빠지',
            201030,
            '부산',
            201225,
            '루쏘호텔',
            210116,
            '광주',
            210313,
            '속초',
            210404,
            '꽃구경',
            210501,
            '2주년 호캉스!',
          ].map((item, i) => (
            <span key={i}>{item}</span>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Letter1;
