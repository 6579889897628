export const answer1 = [
  `
결론부터 말씀드리자면 저는 여자친구를 아주 사랑합니다.
어린 아이들은 '아주 크다'는 것을 표현하기 위하여 하늘만큼 땅만큼 이라는 표현을 사용합니다.
하지만 저의 사랑은 겨우 그 정도로는 표현되지 않고, 표현될 수도 없습니다.
왜냐하면 하늘과 땅의 거리는 그리 멀지 않기 때문입니다.
`,
  `
겨울날 소복이 쌓여가는 눈을 밟아 보신 적이 있으십니까,
뽀드득 뽀드득 하는 그 느낌과 소리는 실로 감탄이 나오지 않을 수 없습니다.
하지만 그보다도 좋은 느낌이 바로 그녀의 뽀드득 뽀드득 하는 이 가는 소리입니다.
(실제로는 갈지 않습니다.)
`,
  `
봄에 갓 핀 꽃을 보신 적이 있으십니까,
앙상한 나무들이 생기를 되찾아 가고, 꽃이 피며 세상의 색감이 살아가는 장면은 그 무엇보다 아름다울 테지요.
하지만 그보다 생기가 넘치고 발랄한 것이 그녀의 웃음입니다.
리액션이 얼마나 훌륭한지 아십니까, 사실 이름이 허수현이 아니라 리액수현이었던 것이 아닐까요.
또, 웃는 얼굴이 얼마나 예쁘게요.
가끔 그녀가 뽈뽈뽈 오면 제가 머리를 쓰다듬어 줍니다. 그러면 그녀는 눈을 딱 감고 고개를 젖히고 환하게 웃곤 하지요.
하늘의 신께서는 전지전능하시니, 그녀가 그러고 있을 때 제 옆에 세상에서 가장 귀여운 것들을 어디 한 번 놔둬 보시지요.
웃는 그녀는 그 무엇보다 귀여우니, 아무리 귀여운 것들로 저를 현혹하셔도 저는 그녀에게서 눈길을 한 번도 떼지 않을 것입니다.
`,
  `
여름철 무더운 날 정자에 앉아서 수박화채를 해 먹어 보신 적이 있으십니까,
전 안해봤습니다. 나중에 여자친구랑 제일 먼저 해 볼겁니다. 부러우세요? ㅋㅋ
`,
  `
가을도 써야 되는데 너무 루즈해졌습니다. 다음 문항으로 넘어갈래요. 날씨 점점 더워지는데 빨리 가을됐으면 좋겠어요.
`,
];

export const answer2 = [
  `
우선 큰 추억부터 말해보겠습니다.
저희는 여행을 몇 번 갔습니다. 월미도, 빠지, 부산, 속초 이렇게 네 곳을 다녀왔지요.
월미도의 포인트는 바로 디스코팡팡이었습니다. 그때는 그 바다가 예쁜 줄 알았는데 나중에 부산이랑 속초 가고 다시 생각해보니 디팡이 최고였습니다.
차이나타운 갔다 온 오리커플로 소문난 저희는 DJ분의 공격을 받았습니다. 허나 굴하지 않고 꿋꿋이 버텨냈지요. 마지막에 여자친구가 날아갔는데 제가 잡아줬지 뭡니까 하하.
근육통은 며칠 갔던 것 같습니다.
`,
  `
빠지의 포인트는 재미였습니다. 여자친구가 그토록 가고 싶어했던 곳입니다! 날아갈 것 같을 때마다 여자친구가 소리를 막 지르는데 어찌나 귀엽던지요.
혼란을 틈타 엉덩이도 만지고 구명조끼 안으로 ..도 만지고 했는데 아주 재밌었습니다. 여자친구도 하더라고요. 하하
같은 사람들이랑 갔으니 속초도 여기에 묶자면, 아, 여자친구가 너무 예뻤습니다. 어떻게 그렇게 날이 갈수록 예뻐지기만 하는지!
`,
  `
부산의 포인트는 여행이었습니다. 아마 참된 여행이 아니었나 싶습니다. 모든 것을 즐겼지요.
공학수학 중간고사 끝나자마자 미친듯이 날아갔습니다. 20대의 열정과 체력이 받쳐주었어요.
가장 기억에 남는 곳을 꼽으라 한다면, 해운대와 남포동 횟집이 기억에 남습니다. 진짜 그날 해운대가 미친듯이 예뻤어요. 그 전에 카메라를 샀어야 했는데.
토이저러스도 기억에는 남는데, 힘들어하는 여자친구 데리고 재밌게 놀러다닌 기억이 나는군요.
`,
  `
깜짝 퀴즈: 부산 첫째날 저녁에 먹은 음식은?
`,
  `
저는 이외 굵지는 않아도 소중한 추억들도 많이 기억하고 있습니다. 가령 처음 감바스를 해 먹은 날, 여자친구를 위해 마리편지를 쓰던 날들,
150일이 될 때쯤 화해의 수호자가 된 날도, 한 번 보고 싶다고 사귀기도 전에 여자친구를 위해 택시를 잡아주겠다고 한 시간을 서성인 날도.
여자친구가 돌연 셋째를 사 온 날도, 200일 선물로 롤케익을 주문제작한 것도, 생각지도 못하고 있던 빼빼로데이를 챙겨준 날도(2019),
아프던 날 하루종일 저를 챙겨주고 집안일을 다 해주는 우렁수현이 된 날도, 먼저 코스튬을 사온 2020크리스마스도, 시험 늦은 우현민 깨우러 와준 날도!
`,
];

export const answer3 = [
  `
말로 할게요!
`,
];

export const answer4 = [
  `
말로 할게요!
`,
];
