import React, { useState } from 'react';

import { Letter } from './Contents';

import styles from './Letter4.module.scss';

interface Props {}

const Letter4: React.FC<Props> = () => {
  const [page, setPage] = useState(0);
  return (
    <div className={styles.letter4Wrapper}>
      {Letter[page].map((item, i) => (
        <p className={styles.letterLine} key={i}>
          {item}
        </p>
      ))}
      <div className={styles.buttonWrapper}>
        {page !== 0 && <button className={styles.leftBtn} onClick={() => setPage((p) => p - 1)} />}
        {page !== Letter.length - 1 && <button className={styles.rightBtn} onClick={() => setPage((p) => p + 1)} />}
      </div>
    </div>
  );
};

export default Letter4;
