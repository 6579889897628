import React, { useContext } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router';

import { OpenContext } from '../../SHM2YearPage';

import styles from './Contents.module.scss';

interface Props {}

const Contents: React.FC<Props> = () => {
  const history = useHistory();

  const { opened } = useContext(OpenContext);

  const onClick: React.MouseEventHandler<HTMLElement> = (e) => {
    history.push(`/suhyunmin2year/contents/${(e.target as EventTarget & { name: string }).name}`);
  };

  return (
    <div className={styles.contentsWrapper} onClick={onClick}>
      <div className={styles.sectionWrapper}>
        <button className={styles.section} name="1">
          이력서
        </button>
      </div>
      <div className={styles.sectionWrapper}>
        <button className={styles.section} name="2">
          자기소개서
        </button>
      </div>
      <div className={styles.sectionWrapper}>
        <button className={styles.section} name="3">
          연애계획서
        </button>
      </div>
      <div className={styles.sectionWrapper}>
        <button
          className={classNames(styles.section, ['1', '2', '3'].every((i) => opened.includes(i)) ? styles.show : styles.hide)}
          name="4"
        >
          사랑해 애기야
        </button>
      </div>
    </div>
  );
};

export default Contents;
