import React from 'react';

import PageWrapper from '../../Components/PageWrapper/PageWrapper';

const MainPage: React.FC = () => {
  return (
    <PageWrapper>
      Ping <br /> Pong
    </PageWrapper>
  );
};

export default MainPage;
