import React, { useContext, useEffect } from 'react';

import { OpenContext } from '../../../../SHM2YearPage';

import { answer1, answer2, answer3, answer4 } from './Contents';

import styles from './Letter2.module.scss';

interface Props {}

const Letter2: React.FC<Props> = () => {
  const { setOpened } = useContext(OpenContext);

  useEffect(() => {
    setOpened((opened) => opened.concat('2'));
  }, [setOpened]);
  return (
    <div className={styles.letter2Wrapper}>
      <h4>1. 지원자가 여자친구를 얼마나 사랑하는지 구체적으로 작성하시오.</h4>
      {answer1.map((item, i) => (
        <p key={i}>{item}</p>
      ))}
      <h4>2. 1번 문항 답변의 근거를 위해, 지원자는 여자친구와의 추억을 얼마나 기억하는지 구체적으로 작성하시오.</h4>
      {answer2.map((item, i) => (
        <p key={i}>{item}</p>
      ))}
      <h4>3. 1번 문항 답변의 근거를 위해, 지원자는 여자친구를 위해 어디까지 해 봤는지 구체적으로 작성하시오.</h4>
      {answer3.map((item, i) => (
        <p key={i}>{item}</p>
      ))}
      <h4>4. 1번 문항 답변의 근거를 위해, 지원자는 여자친구에게 얼마나 진심인지 구체적으로 작성하시오.</h4>
      {answer4.map((item, i) => (
        <p key={i}>{item}</p>
      ))}
    </div>
  );
};

export default Letter2;
