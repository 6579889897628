import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import MainPage from './Pages/MainPage/MainPage';
import SHM2YearPage from './Pages/SHM2YearPage/SHM2YearPage';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/suhyunmin2year" component={SHM2YearPage} />
        <Route exact path="/" component={MainPage} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
