import React, { useContext, useEffect } from 'react';

import { OpenContext } from '../../../../SHM2YearPage';

import styles from './Letter3.module.scss';

interface Props {}

const Letter3: React.FC<Props> = () => {
  const { setOpened } = useContext(OpenContext);

  useEffect(() => {
    setOpened((opened) => opened.concat('3'));
  }, [setOpened]);

  return (
    <div className={styles.letter3Wrapper}>
      <h1>연애 계획서</h1>
      <hr />
      <section className={styles.contents}>
        <span>
          <p>만나기</p>
        </span>
        <span>
          <p>빈도: 주말마다 +-알파</p>
          <p>
            데이트: 1~3주에 한번, 제대로 하기!
            <br />
            그동안 너무 여자친구만 데이트코스 짜게 했던 것 같습니다! 이제 저도 더 노력해 보겠습니다!
          </p>
        </span>
        <span>먹기</span>
        <span>항상 여자친구가 먹고 싶은 걸 가져오는데, 저도 가끔 그렇게 해 주면 여자친구가 좋아할 것 같습니다!</span>
        <span>
          <br />
        </span>
        <span>
          <br />
        </span>
        <span>말</span>
        <span>
          요즘 다소 안 이뻐진 거 같긴 합니다! 물론 편하단 건 좋은 뜻도 많지만, 가끔은 기분을 상하게 할 수 있다는 걸 항상 유념하여
          호칭도 더 조심하고, 말투도 더 부드럽게 사용하겠습니다!
        </span>
        <span>
          <br />
        </span>
        <span>
          <br />
        </span>
        <span>게임</span>
        <span>게임(전장)은 최대한 같이 안 있을 때 하고, 같이 있을 땐 더 생산적이고 좋은 데이트에 집중해 보겠습니다!</span>
        <span>
          <br />
        </span>
        <span>
          <br />
        </span>
        <span>섹스</span>
        <span>
          그동안 너무 제 불만만 표출했던 거 같습니다, 이제 여자친구가 더 좋아할 컨텐츠를 더 많이 준비해 볼게요! 애무도 훨씬 열심히
          할 거예요
        </span>
      </section>
    </div>
  );
};

export default Letter3;
